import React, { createContext, useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import store from 'store';
import { useSessionContext } from 'contexts/session';
import { LoadingPage } from 'components/loading';
import { ErrorApp } from 'components/errors';
import { USER_ACCOUNTS } from './account.api';
import { AccountContext } from './account.typings';
import { sortAccounts } from './account.utils';

interface Props {
  children?: React.ReactNode;
}

export const Account = createContext<AccountContext | undefined>(undefined);

export const accountIdKey = 'account_id';

export const AccountProvider = ({ children }: Props) => {
  const { userId } = useSessionContext();
  const storedSelectedAccountId = store.get(accountIdKey);
  const initialSelectedAccountId =
    storedSelectedAccountId && +storedSelectedAccountId;
  const [selectedAccountId, setSelectedAccountIdState] = useState<
    number | undefined
  >(initialSelectedAccountId);
  const { data, error, loading } = useQuery<
    UserAccountsQuery,
    UserAccountsQueryVariables
  >(USER_ACCOUNTS, {
    variables: { userId },
    skip: !userId,
    onCompleted: data =>
      window.Localize?.setLanguage(data.user.languageShortCode),
  });

  const sortedAccounts = useMemo(() => {
    if (!data) return;
    return sortAccounts([...data.user.accounts]);
  }, [data]);

  const setSelectedAccountId = useMemo(
    () =>
      (accountId: number): void => {
        setSelectedAccountIdState(accountId);
        store.set(accountIdKey, accountId);
      },
    [setSelectedAccountIdState]
  );

  const selectedAccount = sortedAccounts?.find(
    account => account.id === selectedAccountId
  );

  useEffect(() => {
    if (sortedAccounts && !selectedAccount) {
      setSelectedAccountId(sortedAccounts[0].id);
    }
  }, [sortedAccounts, setSelectedAccountId, selectedAccount]);

  if (error) return <ErrorApp />;
  if (loading || !selectedAccount || !selectedAccountId || !data)
    return <LoadingPage />;

  let value: AccountContext | undefined;

  if (selectedAccount) {
    value = {
      userId: data.user.id,
      firstName: data.user.firstName,
      language: data.user.languageShortCode,
      customerType: selectedAccount.customerType,
      selectedAccountId,
      setSelectedAccountId,
      selectedAccount,
      accounts: sortedAccounts!, // sortedAccounts is defined if selectedAccount is defined
      state: data.user.zipCode?.state?.name,
      userOriginationSource: data.user.originationSource?.funnel,
    };
  }

  return <Account.Provider value={value}>{children}</Account.Provider>;
};
