import gql from 'graphql-tag';
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { useAccountContext } from 'contexts/account';
import { priorityTypes } from 'domain/notifications';

export type Notifications =
  GetAccountNotificationsQuery['account']['notifications'];

export const useDashboardNotifications = (
  input,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  const { selectedAccountId } = useAccountContext();
  const { data, error, loading, refetch } = useQuery<
    GetDashboardNotificationsQuery,
    GetDashboardNotificationsQueryVariables
  >(GET_DASHBOARD_NOTIFICATIONS, {
    variables: {
      id: selectedAccountId,
      input,
    },
    fetchPolicy,
    nextFetchPolicy: 'cache-and-network',
  });

  return {
    data,
    error,
    loading,
    refetch,
  };
};

export const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationData on Notification {
    id
    actionType
    active
    createdAt
    displayValues
    notificationKey
    priorityType
    targetId
    read
    notifiableId
    notifiablePlanId
    utilityAccountDetails {
      accountNumber
      displayServiceAddress
      utilityDisplayName
    }
  }
`;

export const GET_DASHBOARD_NOTIFICATIONS = gql`
  query GetDashboardNotifications(
    $id: Int!
    $input: DashboardNotificationsInput
  ) {
    account(id: $id) {
      id
      dashboardNotifications(input: $input) {
        notifications {
          ...NotificationData
        }
        currentNotifications {
          ...NotificationData
        }
        pastNotifications {
          ...NotificationData
        }
        totalCount
        totalCurrentNotifications
        totalPastNotifications
        hasSomeHighPriority
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const currentNotification = {
  actionType: 'no_action',
  active: true,
  createdAt: '2021-01-10',
  displayValues: {
    buttonText: 'click me',
    message: 'Look at this!',
    title: 'A notification',
  },
  id: 2,
  notificationKey: 'my_key',
  priorityType: priorityTypes.update,
  read: false,
  targetId: 1,
  notifiableId: 2,
  notifiablePlanId: 1,
  utilityAccountDetails: null,
};

export const pastNotification = {
  actionType: 'no_action',
  active: true,
  createdAt: '2021-02-02',
  displayValues: {
    buttonText: 'click at me, bro',
    message: 'Hold my beer!',
    title: 'A past notification',
  },
  id: 3,
  notificationKey: 'my_key',
  priorityType: priorityTypes.update,
  read: true,
  targetId: 1,
  notifiableId: 2,
  notifiablePlanId: 1,
  utilityAccountDetails: {
    accountNumber: '123456',
    displayServiceAddress: '123 Main St',
    utilityDisplayName: 'Utility Co',
  },
};

export const dashboardNotification = {
  actionType: 'no_action',
  active: true,
  createdAt: '2021-02-02',
  displayValues: {
    buttonText: 'click at me, bro',
    message: 'Hold my hat!',
    title: 'A dashboard notification',
  },
  id: 4,
  notificationKey: 'my_key',
  priorityType: priorityTypes.low,
  read: true,
  targetId: 1,
  notifiableId: 2,
  notifiablePlanId: 1,
  utilityAccountDetails: {
    accountNumber: '123456',
    displayServiceAddress: '123 Main St',
    utilityDisplayName: 'Utility Co',
  },
};

export const getAccountNotificationsMock = {
  account: {
    id: 1,
    notifications: [
      { __typename: 'Notification', ...currentNotification },
      { __typename: 'Notification', ...pastNotification },
      { __typename: 'Notification', ...dashboardNotification },
    ],
  },
};

export const useAccountNotificationsMock = {
  data: {
    currentNotifications: [currentNotification],
    pastNotifications: [pastNotification, dashboardNotification],
    dashboardNotifications: [dashboardNotification],
  },
  loading: false,
};
