import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useAccountContext } from 'contexts/account';

export const useAccountPlansCount = () => {
  const { selectedAccountId } = useAccountContext();
  const { data, error, loading, refetch } = useQuery<
    GetAccountPlansCountQuery,
    GetAccountPlansCountQueryVariables
  >(GET_ACCOUNT_PLANS_COUNT, { variables: { accountId: selectedAccountId } });

  const accountPlansCountData = useMemo(() => {
    if (!data) return undefined;
    const { inactive, active } = data.account.planStatusCounts;
    return {
      isPlanWhale: active + inactive > 10,
      inactive,
      active,
    };
  }, [data]);

  return { data: accountPlansCountData, error, loading, refetch };
};

export const GET_ACCOUNT_PLANS_COUNT = gql`
  query getAccountPlansCount($accountId: Int!) {
    account(id: $accountId) {
      id
      planStatusCounts {
        active
        inactive
      }
    }
  }
`;

export const accountPlansCountMock = {
  account: {
    __typename: 'Account',
    id: 2,
    planStatusCounts: {
      active: 2,
      inactive: 1,
    },
  },
};

export const useAccountPlansCountMock = {
  data: {
    ...accountPlansCountMock.account.planStatusCounts,
    isPlanWhale: false,
  },
  loading: false,
  refetch: () => null,
};

export const useAccountPlansCountLoadingMock = {
  data: undefined,
  loading: true,
  refetch: () => null,
};
