import React from 'react';
import { useLocation } from 'react-router-dom';
import { Text, Icon } from '@arcadiapower/shrike';
import { IconProps } from 'types/style';
import { isActive, getNavlinkColor } from '../navigation.utils';
import { NavLink } from '../nav-link';
import { ActiveBorderContainer } from './nav-link-tab.style';

interface Props {
  activeMatcher?: (a: string, b: string) => boolean;
  copy?: string;
  htmlProps?: any;
  margin?: Margin;
  path: string;
  padding?: string;
  icon?: IconProps['icon'];
  children?: React.ReactElement;
  textHover?: boolean;
  onClick?: () => void;
}

export const NavLinkTab = (props: Props) => {
  const {
    activeMatcher,
    copy,
    htmlProps,
    margin,
    path,
    padding,
    icon,
    children,
    textHover,
    onClick,
  } = props;
  const { pathname } = useLocation();
  const isActiveTab = activeMatcher
    ? activeMatcher(pathname, path)
    : isActive(pathname, path);
  const color = getNavlinkColor(isActiveTab);

  return (
    <ActiveBorderContainer active={isActiveTab} margin={margin}>
      <NavLink
        {...htmlProps}
        path={path}
        padding={padding}
        margin="0"
        textHover={textHover}
        onClick={onClick}
      >
        {icon && <Icon color={color} icon={icon} margin={{ right: '12px' }} />}
        {copy && (
          <Text textStyle="heading500" color={color}>
            {copy}
          </Text>
        )}
        {children}
      </NavLink>
    </ActiveBorderContainer>
  );
};
